<template>
	<component
		:is="elementType"
		:to="to"
		:href="href"
		:target="target"
		:class="{
			'rounded-sm inline-flex align-middle items-center': true,
			'uppercase': upperCase,
			// sizes
			'border py-3 px-2 gap text-xs': size === SIZE.SMALL,
			'border-2 py-2 px-4 gap-2 text-base': size === SIZE.MEDIUM,
			'border-4 py-5 px-10 gap-2 text-lg': size === SIZE.LARGE,

			//font size
			'font-normal' : fontSize === FONTSIZE.NORMAL,
			'font-semibold' : fontSize === FONTSIZE.SEMIBOLD,
			'font-bold' : fontSize === FONTSIZE.BOLD,

			// primary
			'border-transparent bg-green-600 text-green-50 hover:bg-green-700 hover:shadow-md focus:bg-green-600 focus:shadow-md focus:outline focus:outline-green-300':
				weight === WEIGHT.PRIMARY && action === ACTION.NORMAL && !disabled,
			'border-transparent bg-red-600 text-red-50 hover:bg-red-700 hover:shadow-md focus:bg-red-600 focus:shadow-md focus:outline focus:outline-red-300':
				weight === WEIGHT.PRIMARY && action === ACTION.DESTRUCTIVE && !disabled,
			'border-transparent bg-gray-600 text-gray-200':
				weight === WEIGHT.PRIMARY && disabled,

			// secondary
			'border-blue-300 text-blue-700 hover:bg-blue-100':
				weight === WEIGHT.SECONDARY && action === ACTION.NORMAL && !disabled,
			'border-red-300 text-red-700 hover:bg-red-100':
				weight === WEIGHT.SECONDARY &&
				action === ACTION.DESTRUCTIVE &&
				!disabled,
			'border-gray-400 bg-gray-300 text-gray-700':
				weight === WEIGHT.SECONDARY &&
				action !== ACTION.CONSTRUCTIVE &&
				disabled,
			'border-dashed text-blue-700 border-blue-300 shadow-inner-stripe hover:bg-blue-100':
				weight === WEIGHT.SECONDARY &&
				action === ACTION.CONSTRUCTIVE &&
				!disabled,
			'border-dashed border-gray-200':
				weight === WEIGHT.SECONDARY &&
				action === ACTION.CONSTRUCTIVE &&
				disabled,

			// tertiary
			'border-transparent text-blue-700 hover:underline':
				weight === WEIGHT.TERTIARY && action === ACTION.NORMAL && !disabled,
			'border-transparent text-red-700 hover:underline':
				weight === WEIGHT.TERTIARY &&
				action === ACTION.DESTRUCTIVE &&
				!disabled,
			'border-transparent text-gray-700':
				weight === WEIGHT.TERTIARY && disabled,
		}"
		:disabled="disabled"
	>
		<svg-icon
			v-if="iconBefore"
			:name="iconBefore"
			class="fill-current"
			:class="{
				// sizes
				'w-4 h-4': size === SIZE.SMALL,
				'w-5 h-5': size === SIZE.MEDIUM,
				'w-6 h-6': size === SIZE.LARGE,

				// weight
				'text-green-400':
					weight === WEIGHT.PRIMARY && action === ACTION.NORMAL && !disabled,
				'text-red-400':
					weight === WEIGHT.PRIMARY &&
					action === ACTION.DESTRUCTIVE &&
					!disabled,
				'text-gray-400': weight === WEIGHT.PRIMARY && disabled,
				'': weight === WEIGHT.SECONDARY && !disabled,
				'': weight === WEIGHT.SECONDARY && disabled,
				'': weight === WEIGHT.TERTIARY && !disabled,
				'': weight === WEIGHT.TERTIARY && disabled,

				// other stuff
				'animate-spin': spinIcon,
			}"
		/>
		<slot />
		<svg-icon
			v-if="iconAfter"
			:name="iconAfter"
			class="fill-current"
			:class="{
				// sizes
				'w-4 h-4': size === SIZE.SMALL,
				'w-5 h-5': size === SIZE.MEDIUM,
				'w-6 h-6': size === SIZE.LARGE,

				// action
				'': action === ACTION.NORMAL,
				'': action === ACTION.DESTRUCTIVE,
				'': action === ACTION.CONSTRUCTIVE,

				// weight
				'text-green-400': weight === WEIGHT.PRIMARY && !disabled,
				'text-gray-400': weight === WEIGHT.PRIMARY && disabled,
				'': weight === WEIGHT.SECONDARY && !disabled,
				'': weight === WEIGHT.SECONDARY && disabled,
				'': weight === WEIGHT.TERTIARY && !disabled,
				'': weight === WEIGHT.TERTIARY && disabled,

				// other stuff
				'animate-spin': spinIcon,
			}"
		/>
		<!-- button testing
				<h3>Primary</h3>
				<div class="p-4 flex gap-2">
					<Button weight="primary" action="normal">Normal</Button>
					<Button weight="primary" action="normal" disabled
						>Normal Disabled</Button
					>
					<Button weight="primary" action="destructive">Destructive</Button>
					<Button weight="primary" action="destructive" disabled
						>Destructive Disabled</Button
					>
				</div>
				<h3>Secondary</h3>
				<div class="p-4 flex gap-2">
					<Button weight="secondary" action="normal">Normal</Button>
					<Button weight="secondary" action="normal" disabled
						>Normal Disabled</Button
					>
					<Button weight="secondary" action="destructive">Destructive</Button>
					<Button weight="secondary" action="destructive" disabled
						>Destructive Disabled</Button
					>
					<Button weight="secondary" action="constructive">Constructive</Button>
					<Button weight="secondary" action="constructive" disabled
						>Constructive Disabled</Button
					>
				</div>
				<h3>Tertiary</h3>
				<div class="p-4 flex gap-2">
					<Button weight="tertiary" action="normal">Normal</Button>
					<Button weight="tertiary" action="normal" disabled
						>Normal Disabled</Button
					>
					<Button weight="tertiary" action="destructive">Destructive</Button>
					<Button weight="tertiary" action="destructive" disabled
						>Destructive Disabled</Button
					>
				</div>
-->
	</component>
</template>
<script>
const FONTSIZE = {
	NORMAL: 'normal',
	SEMIBOLD: 'semibold',
	BOLD: 'bold',
}
const SIZE = {
	SMALL: 'small',
	MEDIUM: 'medium',
	LARGE: 'large',
}
const ACTION = {
	NORMAL: 'normal',
	DESTRUCTIVE: 'destructive',
	CONSTRUCTIVE: 'constructive',
}
const WEIGHT = {
	PRIMARY: 'primary',
	SECONDARY: 'secondary',
	TERTIARY: 'tertiary',
}
export default {
	props: {
		to: {
			type: [String, Object],
			required: false,
			default: undefined,
		},
		href: {
			type: String,
			required: false,
			default: undefined,
		},
		target: {
			type: String,
			required: false,
			default: undefined,
		},
		append: {
			type: Boolean,
			required: false,
			default: false,
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false,
		},
		iconBefore: {
			type: String,
			required: false,
			default: undefined,
		},
		iconAfter: {
			type: String,
			required: false,
			default: undefined,
		},
		spinIcon: {
			type: Boolean,
			required: false,
			default: false,
		},
    upperCase: {
      type: Boolean,
      required: false,
      default: true
    },
		size: {
			validator(value) {
				// The value must match one of these strings
				return [SIZE.SMALL, SIZE.MEDIUM, SIZE.LARGE].includes(value)
			},
			default: SIZE.MEDIUM,
		},
		fontSize: {
			validator(value) {
				// The value must match one of these strings
				return [FONTSIZE.NORMAL, FONTSIZE.SEMIBOLD, FONTSIZE.BOLD].includes(value)
			},
			default: FONTSIZE.NORMAL,
		},
		action: {
			validator(value) {
				// The value must match one of these strings
				return [
					ACTION.NORMAL,
					ACTION.DESTRUCTIVE,
					ACTION.CONSTRUCTIVE,
				].includes(value)
			},
			default: ACTION.NORMAL,
		},
		weight: {
			validator(value) {
				// The value must match one of these strings
				return [WEIGHT.PRIMARY, WEIGHT.SECONDARY, WEIGHT.TERTIARY].includes(
					value
				)
			},
			default: WEIGHT.PRIMARY,
		}
	},
	data() {
		return {
			SIZE,
			ACTION,
			WEIGHT,
			FONTSIZE,
		}
	},
	computed: {
		elementType() {
			if (this.to) {
				return 'nuxt-link'
			}
			if (this.href) {
				return 'a'
			}
			return 'button'
		},
	},
}
</script>
